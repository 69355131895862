<template>
  <div style="max-width: 1000px; margin: auto">
    <br />
    <Card>
      <template #header>
        <br />
        <label style="text-align: left"
          >Envoyez un message à tout le monde :</label
        >
      </template>
      <template #content>
        <Textarea
          style="max-width: 800px; width: 100%; height: 100px"
          v-model="messagevalue"
          :autoResize="true" />
       <img
          style="max-height: 200px; margin-top:20px"
          v-bind:src="url.replace('upload/', 'upload/h_200/')"
        /></template>
      <template #footer>
        <UploadCloudinary
          folder="medias"
          iscamera
          @result="onUploaded($event)"
        />
        <Button
          style="margin-left: 5px"
          label="Envoyer le message"
          @click="onNewMessage"
          icon="pi pi-comment"
          iconPos="right"
        />
      </template>
    </Card>
    <div class="p-grid p-flex-column">
      <div class="p-col">
        <div
          class="p-row box"
          is="messages-item"
          v-for="message in orderedMessages"
          v-bind:key="message.id"
          :messageItem="message"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Messages from "../components/Messages.vue";
import { store } from "../store";
//import _ from 'lodash';

var moment = require("moment");

export default {
  name: "Messagerie",
  components: {
    "messages-item": Messages,
  },
  computed: {
    Messages() {
      return this.orderedMessages();
    },
    orderedMessages() {
      var ordered = store.state.messages.sort(function (x, y) {
        return (
          moment(x.timestamp, "DD/MM/YYYY HH:mm:ss") -
          moment(y.timestamp, "DD/MM/YYYY HH:mm:ss")
        );
      });
      var reversed = ordered.reverse();
      return reversed;
    },
  },
  methods: {
    onNewMessage() {
      if (this.messagevalue === "" && this.url === "") {
        this.$toast.add({
          severity: "error",
          summary: "Pas de message",
          detail: "Veuillez ajouter un message ou une image",
          life: 3000,
        });
      } else {
        var mes = {
          content: this.messagevalue,
          userid: store.state.userProfile.id,
          timestamp: moment().format("DD/MM/YYYY HH:mm:ss"),
          url: this.url,
        };
        store.dispatch("addMessage", mes);

        this.url = "";
        this.messagevalue = "";

        this.$toast.add({
          severity: "success",
          summary: "Message bien ajouté",
          detail: "Message bien ajouté",
          life: 3000,
        });
      }
    },
    onUploaded(e) {
      this.url = e.secure_url;
    },
  },
  created() {
    store.dispatch("bindMessages");
  },
  data() {
    return {
      message: {
        content: "",
        userid: "",
        timestamp: moment().format("DD/MM/YYYY hh:mm:ss"),
      },
      messagevalue: "",
      url: "",
    };
  },
};
</script>


