<template>
  <div>
    <Card >
      <template #header>
        <div class="p-grid" style="margin-top: 15px;margin-left: 15px;">
          <div
            style="
              text-align: left;
              margin-right: 15px;
              margin-top: auto;
              margin-bottom: auto;
            "
          >
            <img
              v-bind:alt="getUser(messageItem.userid).prenom"
              v-bind:src="
                getUser(messageItem.userid).photo.replace(
                  'upload/',
                  'upload/h_75/'
                )
              "
              style="width: 50px; border-radius: 50%"
            />
          </div>
          <div style="text-align: left; margin-top: auto; margin-bottom: auto">
            <h3>
              {{ getUser(messageItem.userid).prenom }}
              {{ getUser(messageItem.userid).nom }}
            </h3>
          </div>
        </div>
      </template>
      <template #content>
        <div style="overflow-wrap: anywhere;">
        <img
          style="max-height: 200px"
          v-bind:src="messageItem.url.replace('upload/', 'upload/h_200/')"
        /><br />{{ messageItem.content }}</div></template
      >
      
      <template #footer>
        <div style="text-align: right">{{ messageItem.timestamp }}</div>
      </template>
    </Card>
  </div>
</template>

<script>
import { store } from "../store";

export default {
  props: ["messageItem"],
  methods: {
    getUser(id) {
      var user = {
        prenom: "inconnu",
        nom: "",
        photo: "",
      };
      store.state.users.forEach((element) => {
        if (element.id === id) user = element;
      });
      return user;
    },
  },
  created() {
    store.dispatch("bindUsers");
  },
};
</script>